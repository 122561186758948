export default [
  {
    value: '650000',
    children: [{
      value: '650100',
      children: [{
        value: '650102',
        children: [],
        label: '天山区',
        level: 3
      },
      {
        value: '650103',
        children: [],
        label: '沙依巴克区',
        level: 3
      },
      {
        value: '650104',
        children: [],
        label: '新市区',
        level: 3
      },
      {
        value: '650105',
        children: [],
        label: '水磨沟区',
        level: 3
      },
      {
        value: '650106',
        children: [],
        label: '头屯河区',
        level: 3
      },
      {
        value: '650107',
        children: [],
        label: '达坂城区',
        level: 3
      },
      {
        value: '650109',
        children: [],
        label: '米东区',
        level: 3
      },
      {
        value: '650121',
        children: [],
        label: '乌鲁木齐县',
        level: 3
      },
      {
        value: '650171',
        children: [],
        label: '乌鲁木齐经济技术开发区',
        level: 3
      },
      {
        value: '650172',
        children: [],
        label: '乌鲁木齐高新技术产业开发区',
        level: 3
      }],
      label: '乌鲁木齐市',
      level: 2
    },
    {
      value: '650200',
      children: [{
        value: '650202',
        children: [],
        label: '独山子区',
        level: 3
      },
      {
        value: '650203',
        children: [],
        label: '克拉玛依区',
        level: 3
      },
      {
        value: '650204',
        children: [],
        label: '白碱滩区',
        level: 3
      },
      {
        value: '650205',
        children: [],
        label: '乌尔禾区',
        level: 3
      }],
      label: '克拉玛依市',
      level: 2
    },
    {
      value: '650400',
      children: [{
        value: '650402',
        children: [],
        label: '高昌区',
        level: 3
      },
      {
        value: '650421',
        children: [],
        label: '鄯善县',
        level: 3
      },
      {
        value: '650422',
        children: [],
        label: '托克逊县',
        level: 3
      }],
      label: '吐鲁番市',
      level: 2
    },
    {
      value: '650500',
      children: [{
        value: '650502',
        children: [],
        label: '伊州区',
        level: 3
      },
      {
        value: '650521',
        children: [],
        label: '巴里坤哈萨克自治县',
        level: 3
      },
      {
        value: '650522',
        children: [],
        label: '伊吾县',
        level: 3
      }],
      label: '哈密市',
      level: 2
    },
    {
      value: '652300',
      children: [{
        value: '652301',
        children: [],
        label: '昌吉市',
        level: 3
      },
      {
        value: '652302',
        children: [],
        label: '阜康市',
        level: 3
      },
      {
        value: '652323',
        children: [],
        label: '呼图壁县',
        level: 3
      },
      {
        value: '652324',
        children: [],
        label: '玛纳斯县',
        level: 3
      },
      {
        value: '652325',
        children: [],
        label: '奇台县',
        level: 3
      },
      {
        value: '652327',
        children: [],
        label: '吉木萨尔县',
        level: 3
      },
      {
        value: '652328',
        children: [],
        label: '木垒哈萨克自治县',
        level: 3
      }],
      label: '昌吉回族自治州',
      level: 2
    },
    {
      value: '652700',
      children: [{
        value: '652701',
        children: [],
        label: '博乐市',
        level: 3
      },
      {
        value: '652702',
        children: [],
        label: '阿拉山口市',
        level: 3
      },
      {
        value: '652722',
        children: [],
        label: '精河县',
        level: 3
      },
      {
        value: '652723',
        children: [],
        label: '温泉县',
        level: 3
      }],
      label: '博尔塔拉蒙古自治州',
      level: 2
    },
    {
      value: '652800',
      children: [{
        value: '652801',
        children: [],
        label: '库尔勒市',
        level: 3
      },
      {
        value: '652822',
        children: [],
        label: '轮台县',
        level: 3
      },
      {
        value: '652823',
        children: [],
        label: '尉犁县',
        level: 3
      },
      {
        value: '652824',
        children: [],
        label: '若羌县',
        level: 3
      },
      {
        value: '652825',
        children: [],
        label: '且末县',
        level: 3
      },
      {
        value: '652826',
        children: [],
        label: '焉耆回族自治县',
        level: 3
      },
      {
        value: '652827',
        children: [],
        label: '和静县',
        level: 3
      },
      {
        value: '652828',
        children: [],
        label: '和硕县',
        level: 3
      },
      {
        value: '652829',
        children: [],
        label: '博湖县',
        level: 3
      },
      {
        value: '652871',
        children: [],
        label: '库尔勒经济技术开发区',
        level: 3
      }],
      label: '巴音郭楞蒙古自治州',
      level: 2
    },
    {
      value: '652900',
      children: [{
        value: '652901',
        children: [],
        label: '阿克苏市',
        level: 3
      },
      {
        value: '652922',
        children: [],
        label: '温宿县',
        level: 3
      },
      {
        value: '652923',
        children: [],
        label: '库车县',
        level: 3
      },
      {
        value: '652924',
        children: [],
        label: '沙雅县',
        level: 3
      },
      {
        value: '652925',
        children: [],
        label: '新和县',
        level: 3
      },
      {
        value: '652926',
        children: [],
        label: '拜城县',
        level: 3
      },
      {
        value: '652927',
        children: [],
        label: '乌什县',
        level: 3
      },
      {
        value: '652928',
        children: [],
        label: '阿瓦提县',
        level: 3
      },
      {
        value: '652929',
        children: [],
        label: '柯坪县',
        level: 3
      }],
      label: '阿克苏地区',
      level: 2
    },
    {
      value: '653000',
      children: [{
        value: '653001',
        children: [],
        label: '阿图什市',
        level: 3
      },
      {
        value: '653022',
        children: [],
        label: '阿克陶县',
        level: 3
      },
      {
        value: '653023',
        children: [],
        label: '阿合奇县',
        level: 3
      },
      {
        value: '653024',
        children: [],
        label: '乌恰县',
        level: 3
      }],
      label: '克孜勒苏柯尔克孜自治州',
      level: 2
    },
    {
      value: '653100',
      children: [{
        value: '653101',
        children: [],
        label: '喀什市',
        level: 3
      },
      {
        value: '653121',
        children: [],
        label: '疏附县',
        level: 3
      },
      {
        value: '653122',
        children: [],
        label: '疏勒县',
        level: 3
      },
      {
        value: '653123',
        children: [],
        label: '英吉沙县',
        level: 3
      },
      {
        value: '653124',
        children: [],
        label: '泽普县',
        level: 3
      },
      {
        value: '653125',
        children: [],
        label: '莎车县',
        level: 3
      },
      {
        value: '653126',
        children: [],
        label: '叶城县',
        level: 3
      },
      {
        value: '653127',
        children: [],
        label: '麦盖提县',
        level: 3
      },
      {
        value: '653128',
        children: [],
        label: '岳普湖县',
        level: 3
      },
      {
        value: '653129',
        children: [],
        label: '伽师县',
        level: 3
      },
      {
        value: '653130',
        children: [],
        label: '巴楚县',
        level: 3
      },
      {
        value: '653131',
        children: [],
        label: '塔什库尔干塔吉克自治县',
        level: 3
      }],
      label: '喀什地区',
      level: 2
    },
    {
      value: '653200',
      children: [{
        value: '653201',
        children: [],
        label: '和田市',
        level: 3
      },
      {
        value: '653221',
        children: [],
        label: '和田县',
        level: 3
      },
      {
        value: '653222',
        children: [],
        label: '墨玉县',
        level: 3
      },
      {
        value: '653223',
        children: [],
        label: '皮山县',
        level: 3
      },
      {
        value: '653224',
        children: [],
        label: '洛浦县',
        level: 3
      },
      {
        value: '653225',
        children: [],
        label: '策勒县',
        level: 3
      },
      {
        value: '653226',
        children: [],
        label: '于田县',
        level: 3
      },
      {
        value: '653227',
        children: [],
        label: '民丰县',
        level: 3
      }],
      label: '和田地区',
      level: 2
    },
    {
      value: '654000',
      children: [{
        value: '654002',
        children: [],
        label: '伊宁市',
        level: 3
      },
      {
        value: '654003',
        children: [],
        label: '奎屯市',
        level: 3
      },
      {
        value: '654004',
        children: [],
        label: '霍尔果斯市',
        level: 3
      },
      {
        value: '654021',
        children: [],
        label: '伊宁县',
        level: 3
      },
      {
        value: '654022',
        children: [],
        label: '察布查尔锡伯自治县',
        level: 3
      },
      {
        value: '654023',
        children: [],
        label: '霍城县',
        level: 3
      },
      {
        value: '654024',
        children: [],
        label: '巩留县',
        level: 3
      },
      {
        value: '654025',
        children: [],
        label: '新源县',
        level: 3
      },
      {
        value: '654026',
        children: [],
        label: '昭苏县',
        level: 3
      },
      {
        value: '654027',
        children: [],
        label: '特克斯县',
        level: 3
      },
      {
        value: '654028',
        children: [],
        label: '尼勒克县',
        level: 3
      }],
      label: '伊犁哈萨克自治州',
      level: 2
    },
    {
      value: '654200',
      children: [{
        value: '654201',
        children: [],
        label: '塔城市',
        level: 3
      },
      {
        value: '654202',
        children: [],
        label: '乌苏市',
        level: 3
      },
      {
        value: '654221',
        children: [],
        label: '额敏县',
        level: 3
      },
      {
        value: '654223',
        children: [],
        label: '沙湾县',
        level: 3
      },
      {
        value: '654224',
        children: [],
        label: '托里县',
        level: 3
      },
      {
        value: '654225',
        children: [],
        label: '裕民县',
        level: 3
      },
      {
        value: '654226',
        children: [],
        label: '和布克赛尔蒙古自治县',
        level: 3
      }],
      label: '塔城地区',
      level: 2
    },
    {
      value: '654300',
      children: [{
        value: '654301',
        children: [],
        label: '阿勒泰市',
        level: 3
      },
      {
        value: '654321',
        children: [],
        label: '布尔津县',
        level: 3
      },
      {
        value: '654322',
        children: [],
        label: '富蕴县',
        level: 3
      },
      {
        value: '654323',
        children: [],
        label: '福海县',
        level: 3
      },
      {
        value: '654324',
        children: [],
        label: '哈巴河县',
        level: 3
      },
      {
        value: '654325',
        children: [],
        label: '青河县',
        level: 3
      },
      {
        value: '654326',
        children: [],
        label: '吉木乃县',
        level: 3
      }],
      label: '阿勒泰地区',
      level: 2
    },
    {
      value: '659000',
      children: [{
        value: '659001',
        children: [],
        label: '石河子市',
        level: 3
      },
      {
        value: '659002',
        children: [],
        label: '阿拉尔市',
        level: 3
      },
      {
        value: '659003',
        children: [],
        label: '图木舒克市',
        level: 3
      },
      {
        value: '659004',
        children: [],
        label: '五家渠市',
        level: 3
      },
      {
        value: '659005',
        children: [],
        label: '北屯市',
        level: 3
      },
      {
        value: '659006',
        children: [],
        label: '铁门关市',
        level: 3
      },
      {
        value: '659007',
        children: [],
        label: '双河市',
        level: 3
      },
      {
        value: '659008',
        children: [],
        label: '可克达拉市',
        level: 3
      },
      {
        value: '659009',
        children: [],
        label: '昆玉市',
        level: 3
      }],
      label: '自治区直辖县级行政区划',
      level: 2
    }],
    label: '新疆维吾尔自治区',
    level: 1
  },
  {
    value: '110000',
    children: [{
      value: '110100',
      label: '北京市',
      level: 2,
      leaf: true
    }],
    label: '北京',
    level: 1
  },
  {
    value: '120000',
    children: [{
      value: '120100',
      children: [],
      label: '天津市',
      level: 2,
      leaf: true
    }],
    label: '天津',
    level: 1
  },
  {
    value: '130000',
    children: [{
      value: '130100',
      children: [],
      label: '石家庄市',
      level: 2
    },
    {
      value: '130200',
      children: [],
      label: '唐山市',
      level: 2
    },
    {
      value: '130300',
      children: [],
      label: '秦皇岛市',
      level: 2
    },
    {
      value: '130400',
      children: [],
      label: '邯郸市',
      level: 2
    },
    {
      value: '130500',
      children: [],
      label: '邢台市',
      level: 2
    },
    {
      value: '130600',
      children: [],
      label: '保定市',
      level: 2
    },
    {
      value: '130700',
      children: [],
      label: '张家口市',
      level: 2
    },
    {
      value: '130800',
      children: [],
      label: '承德市',
      level: 2
    },
    {
      value: '130900',
      children: [],
      label: '沧州市',
      level: 2
    },
    {
      value: '131000',
      children: [],
      label: '廊坊市',
      level: 2
    },
    {
      value: '131100',
      children: [],
      label: '衡水市',
      level: 2
    }],
    label: '河北省',
    level: 1
  },
  {
    value: '140000',
    children: [{
      value: '140100',
      children: [],
      label: '太原市',
      level: 2
    },
    {
      value: '140200',
      children: [],
      label: '大同市',
      level: 2
    },
    {
      value: '140300',
      children: [],
      label: '阳泉市',
      level: 2
    },
    {
      value: '140400',
      children: [],
      label: '长治市',
      level: 2
    },
    {
      value: '140500',
      children: [],
      label: '晋城市',
      level: 2
    },
    {
      value: '140600',
      children: [],
      label: '朔州市',
      level: 2
    },
    {
      value: '140700',
      children: [],
      label: '晋中市',
      level: 2
    },
    {
      value: '140800',
      children: [],
      label: '运城市',
      level: 2
    },
    {
      value: '140900',
      children: [],
      label: '忻州市',
      level: 2
    },
    {
      value: '141000',
      children: [],
      label: '临汾市',
      level: 2
    },
    {
      value: '141100',
      children: [],
      label: '吕梁市',
      level: 2
    }],
    label: '山西省',
    level: 1
  },
  {
    value: '150000',
    children: [{
      value: '150100',
      children: [],
      label: '呼和浩特市',
      level: 2
    },
    {
      value: '150200',
      children: [],
      label: '包头市',
      level: 2
    },
    {
      value: '150300',
      children: [],
      label: '乌海市',
      level: 2
    },
    {
      value: '150400',
      children: [],
      label: '赤峰市',
      level: 2
    },
    {
      value: '150500',
      children: [],
      label: '通辽市',
      level: 2
    },
    {
      value: '150600',
      children: [],
      label: '鄂尔多斯市',
      level: 2
    },
    {
      value: '150700',
      children: [],
      label: '呼伦贝尔市',
      level: 2
    },
    {
      value: '150800',
      children: [],
      label: '巴彦淖尔市',
      level: 2
    },
    {
      value: '150900',
      children: [],
      label: '乌兰察布市',
      level: 2
    },
    {
      value: '152200',
      children: [],
      label: '兴安盟',
      level: 2
    },
    {
      value: '152500',
      children: [],
      label: '锡林郭勒盟',
      level: 2
    },
    {
      value: '152900',
      children: [],
      label: '阿拉善盟',
      level: 2
    }],
    label: '内蒙古自治区',
    level: 1
  },
  {
    value: '210000',
    children: [{
      value: '210100',
      children: [],
      label: '沈阳市',
      level: 2
    },
    {
      value: '210200',
      children: [],
      label: '大连市',
      level: 2
    },
    {
      value: '210300',
      children: [],
      label: '鞍山市',
      level: 2
    },
    {
      value: '210400',
      children: [],
      label: '抚顺市',
      level: 2
    },
    {
      value: '210500',
      children: [],
      label: '本溪市',
      level: 2
    },
    {
      value: '210600',
      children: [],
      label: '丹东市',
      level: 2
    },
    {
      value: '210700',
      children: [],
      label: '锦州市',
      level: 2
    },
    {
      value: '210800',
      children: [],
      label: '营口市',
      level: 2
    },
    {
      value: '210900',
      children: [],
      label: '阜新市',
      level: 2
    },
    {
      value: '211000',
      children: [],
      label: '辽阳市',
      level: 2
    },
    {
      value: '211100',
      children: [],
      label: '盘锦市',
      level: 2
    },
    {
      value: '211200',
      children: [],
      label: '铁岭市',
      level: 2
    },
    {
      value: '211300',
      children: [],
      label: '朝阳市',
      level: 2
    },
    {
      value: '211400',
      children: [],
      label: '葫芦岛市',
      level: 2
    }],
    label: '辽宁省',
    level: 1
  },
  {
    value: '220000',
    children: [{
      value: '220100',
      children: [],
      label: '长春市',
      level: 2
    },
    {
      value: '220200',
      children: [],
      label: '吉林市',
      level: 2
    },
    {
      value: '220300',
      children: [],
      label: '四平市',
      level: 2
    },
    {
      value: '220400',
      children: [],
      label: '辽源市',
      level: 2
    },
    {
      value: '220500',
      children: [],
      label: '通化市',
      level: 2
    },
    {
      value: '220600',
      children: [],
      label: '白山市',
      level: 2
    },
    {
      value: '220700',
      children: [],
      label: '松原市',
      level: 2
    },
    {
      value: '220800',
      children: [],
      label: '白城市',
      level: 2
    },
    {
      value: '222400',
      children: [],
      label: '延边朝鲜族自治州',
      level: 2
    }],
    label: '吉林省',
    level: 1
  },
  {
    value: '230000',
    children: [{
      value: '230100',
      children: [],
      label: '哈尔滨市',
      level: 2
    },
    {
      value: '230200',
      children: [],
      label: '齐齐哈尔市',
      level: 2
    },
    {
      value: '230300',
      children: [],
      label: '鸡西市',
      level: 2
    },
    {
      value: '230400',
      children: [],
      label: '鹤岗市',
      level: 2
    },
    {
      value: '230500',
      children: [],
      label: '双鸭山市',
      level: 2
    },
    {
      value: '230600',
      children: [],
      label: '大庆市',
      level: 2
    },
    {
      value: '230700',
      children: [],
      label: '伊春市',
      level: 2
    },
    {
      value: '230800',
      children: [],
      label: '佳木斯市',
      level: 2
    },
    {
      value: '230900',
      children: [],
      label: '七台河市',
      level: 2
    },
    {
      value: '231000',
      children: [],
      label: '牡丹江市',
      level: 2
    },
    {
      value: '231100',
      children: [],
      label: '黑河市',
      level: 2
    },
    {
      value: '231200',
      children: [],
      label: '绥化市',
      level: 2
    },
    {
      value: '232700',
      children: [],
      label: '大兴安岭地区',
      level: 2
    }],
    label: '黑龙江省',
    level: 1
  },
  {
    value: '310000',
    children: [{
      value: '310100',
      children: [],
      label: '上海市',
      level: 2
    }],
    label: '上海',
    level: 1
  },
  {
    value: '320000',
    children: [{
      value: '320100',
      children: [],
      label: '南京市',
      level: 2
    },
    {
      value: '320200',
      children: [],
      label: '无锡市',
      level: 2
    },
    {
      value: '320300',
      children: [],
      label: '徐州市',
      level: 2
    },
    {
      value: '320400',
      children: [],
      label: '常州市',
      level: 2
    },
    {
      value: '320500',
      children: [],
      label: '苏州市',
      level: 2
    },
    {
      value: '320600',
      children: [],
      label: '南通市',
      level: 2
    },
    {
      value: '320700',
      children: [],
      label: '连云港市',
      level: 2
    },
    {
      value: '320800',
      children: [],
      label: '淮安市',
      level: 2
    },
    {
      value: '320900',
      children: [],
      label: '盐城市',
      level: 2
    },
    {
      value: '321000',
      children: [],
      label: '扬州市',
      level: 2
    },
    {
      value: '321100',
      children: [],
      label: '镇江市',
      level: 2
    },
    {
      value: '321200',
      children: [],
      label: '泰州市',
      level: 2
    },
    {
      value: '321300',
      children: [],
      label: '宿迁市',
      level: 2
    }],
    label: '江苏省',
    level: 1
  },
  {
    value: '330000',
    children: [{
      value: '330100',
      children: [],
      label: '杭州市',
      level: 2
    },
    {
      value: '330200',
      children: [],
      label: '宁波市',
      level: 2
    },
    {
      value: '330300',
      children: [],
      label: '温州市',
      level: 2
    },
    {
      value: '330400',
      children: [],
      label: '嘉兴市',
      level: 2
    },
    {
      value: '330500',
      children: [],
      label: '湖州市',
      level: 2
    },
    {
      value: '330600',
      children: [],
      label: '绍兴市',
      level: 2
    },
    {
      value: '330700',
      children: [],
      label: '金华市',
      level: 2
    },
    {
      value: '330800',
      children: [],
      label: '衢州市',
      level: 2
    },
    {
      value: '330900',
      children: [],
      label: '舟山市',
      level: 2
    },
    {
      value: '331000',
      children: [],
      label: '台州市',
      level: 2
    },
    {
      value: '331100',
      children: [],
      label: '丽水市',
      level: 2
    },
    {
      value: '331200',
      children: [],
      label: '舟山群岛新区',
      level: 2
    }],
    label: '浙江省',
    level: 1
  },
  {
    value: '340000',
    children: [{
      value: '340100',
      children: [],
      label: '合肥市',
      level: 2
    },
    {
      value: '340200',
      children: [],
      label: '芜湖市',
      level: 2
    },
    {
      value: '340300',
      children: [],
      label: '蚌埠市',
      level: 2
    },
    {
      value: '340400',
      children: [],
      label: '淮南市',
      level: 2
    },
    {
      value: '340500',
      children: [],
      label: '马鞍山市',
      level: 2
    },
    {
      value: '340600',
      children: [],
      label: '淮北市',
      level: 2
    },
    {
      value: '340700',
      children: [],
      label: '铜陵市',
      level: 2
    },
    {
      value: '340800',
      children: [],
      label: '安庆市',
      level: 2
    },
    {
      value: '341000',
      children: [],
      label: '黄山市',
      level: 2
    },
    {
      value: '341100',
      children: [],
      label: '滁州市',
      level: 2
    },
    {
      value: '341200',
      children: [],
      label: '阜阳市',
      level: 2
    },
    {
      value: '341300',
      children: [],
      label: '宿州市',
      level: 2
    },
    {
      value: '341500',
      children: [],
      label: '六安市',
      level: 2
    },
    {
      value: '341600',
      children: [],
      label: '亳州市',
      level: 2
    },
    {
      value: '341700',
      children: [],
      label: '池州市',
      level: 2
    },
    {
      value: '341800',
      children: [],
      label: '宣城市',
      level: 2
    }],
    label: '安徽省',
    level: 1
  },
  {
    value: '350000',
    children: [{
      value: '350100',
      children: [],
      label: '福州市',
      level: 2
    },
    {
      value: '350200',
      children: [],
      label: '厦门市',
      level: 2
    },
    {
      value: '350300',
      children: [],
      label: '莆田市',
      level: 2
    },
    {
      value: '350400',
      children: [],
      label: '三明市',
      level: 2
    },
    {
      value: '350500',
      children: [],
      label: '泉州市',
      level: 2
    },
    {
      value: '350600',
      children: [],
      label: '漳州市',
      level: 2
    },
    {
      value: '350700',
      children: [],
      label: '南平市',
      level: 2
    },
    {
      value: '350800',
      children: [],
      label: '龙岩市',
      level: 2
    },
    {
      value: '350900',
      children: [],
      label: '宁德市',
      level: 2
    }],
    label: '福建省',
    level: 1
  },
  {
    value: '360000',
    children: [{
      value: '360100',
      children: [],
      label: '南昌市',
      level: 2
    },
    {
      value: '360200',
      children: [],
      label: '景德镇市',
      level: 2
    },
    {
      value: '360300',
      children: [],
      label: '萍乡市',
      level: 2
    },
    {
      value: '360400',
      children: [],
      label: '九江市',
      level: 2
    },
    {
      value: '360500',
      children: [],
      label: '新余市',
      level: 2
    },
    {
      value: '360600',
      children: [],
      label: '鹰潭市',
      level: 2
    },
    {
      value: '360700',
      children: [],
      label: '赣州市',
      level: 2
    },
    {
      value: '360800',
      children: [],
      label: '吉安市',
      level: 2
    },
    {
      value: '360900',
      children: [],
      label: '宜春市',
      level: 2
    },
    {
      value: '361000',
      children: [],
      label: '抚州市',
      level: 2
    },
    {
      value: '361100',
      children: [],
      label: '上饶市',
      level: 2
    }],
    label: '江西省',
    level: 1
  },
  {
    value: '370000',
    children: [{
      value: '370100',
      children: [],
      label: '济南市',
      level: 2
    },
    {
      value: '370200',
      children: [],
      label: '青岛市',
      level: 2
    },
    {
      value: '370300',
      children: [],
      label: '淄博市',
      level: 2
    },
    {
      value: '370400',
      children: [],
      label: '枣庄市',
      level: 2
    },
    {
      value: '370500',
      children: [],
      label: '东营市',
      level: 2
    },
    {
      value: '370600',
      children: [],
      label: '烟台市',
      level: 2
    },
    {
      value: '370700',
      children: [],
      label: '潍坊市',
      level: 2
    },
    {
      value: '370800',
      children: [],
      label: '济宁市',
      level: 2
    },
    {
      value: '370900',
      children: [],
      label: '泰安市',
      level: 2
    },
    {
      value: '371000',
      children: [],
      label: '威海市',
      level: 2
    },
    {
      value: '371100',
      children: [],
      label: '日照市',
      level: 2
    },
    {
      value: '371200',
      children: [],
      label: '莱芜市',
      level: 2
    },
    {
      value: '371300',
      children: [],
      label: '临沂市',
      level: 2
    },
    {
      value: '371400',
      children: [],
      label: '德州市',
      level: 2
    },
    {
      value: '371500',
      children: [],
      label: '聊城市',
      level: 2
    },
    {
      value: '371600',
      children: [],
      label: '滨州市',
      level: 2
    },
    {
      value: '371700',
      children: [],
      label: '菏泽市',
      level: 2
    }],
    label: '山东省',
    level: 1
  },
  {
    value: '410000',
    children: [{
      value: '410100',
      children: [],
      label: '郑州市',
      level: 2
    },
    {
      value: '410200',
      children: [],
      label: '开封市',
      level: 2
    },
    {
      value: '410300',
      children: [],
      label: '洛阳市',
      level: 2
    },
    {
      value: '410400',
      children: [],
      label: '平顶山市',
      level: 2
    },
    {
      value: '410500',
      children: [],
      label: '安阳市',
      level: 2
    },
    {
      value: '410600',
      children: [],
      label: '鹤壁市',
      level: 2
    },
    {
      value: '410700',
      children: [],
      label: '新乡市',
      level: 2
    },
    {
      value: '410800',
      children: [],
      label: '焦作市',
      level: 2
    },
    {
      value: '410900',
      children: [],
      label: '濮阳市',
      level: 2
    },
    {
      value: '411000',
      children: [],
      label: '许昌市',
      level: 2
    },
    {
      value: '411100',
      children: [],
      label: '漯河市',
      level: 2
    },
    {
      value: '411200',
      children: [],
      label: '三门峡市',
      level: 2
    },
    {
      value: '411300',
      children: [],
      label: '南阳市',
      level: 2
    },
    {
      value: '411400',
      children: [],
      label: '商丘市',
      level: 2
    },
    {
      value: '411500',
      children: [],
      label: '信阳市',
      level: 2
    },
    {
      value: '411600',
      children: [],
      label: '周口市',
      level: 2
    },
    {
      value: '411700',
      children: [],
      label: '驻马店市',
      level: 2
    },
    {
      value: '419001',
      children: [],
      label: '济源市',
      level: 2
    }],
    label: '河南省',
    level: 1
  },
  {
    value: '420000',
    children: [{
      value: '420100',
      children: [],
      label: '武汉市',
      level: 2
    },
    {
      value: '420200',
      children: [],
      label: '黄石市',
      level: 2
    },
    {
      value: '420300',
      children: [],
      label: '十堰市',
      level: 2
    },
    {
      value: '420500',
      children: [],
      label: '宜昌市',
      level: 2
    },
    {
      value: '420600',
      children: [],
      label: '襄阳市',
      level: 2
    },
    {
      value: '420700',
      children: [],
      label: '鄂州市',
      level: 2
    },
    {
      value: '420800',
      children: [],
      label: '荆门市',
      level: 2
    },
    {
      value: '420900',
      children: [],
      label: '孝感市',
      level: 2
    },
    {
      value: '421000',
      children: [],
      label: '荆州市',
      level: 2
    },
    {
      value: '421100',
      children: [],
      label: '黄冈市',
      level: 2
    },
    {
      value: '421200',
      children: [],
      label: '咸宁市',
      level: 2
    },
    {
      value: '421300',
      children: [],
      label: '随州市',
      level: 2
    },
    {
      value: '422800',
      children: [],
      label: '恩施土家族苗族自治州',
      level: 2
    },
    {
      value: '429004',
      children: [],
      label: '仙桃市',
      level: 2
    },
    {
      value: '429005',
      children: [],
      label: '潜江市',
      level: 2
    },
    {
      value: '429006',
      children: [],
      label: '天门市',
      level: 2
    },
    {
      value: '429021',
      children: [],
      label: '神农架林区',
      level: 2
    }],
    label: '湖北省',
    level: 1
  },
  {
    value: '430000',
    children: [{
      value: '430100',
      children: [],
      label: '长沙市',
      level: 2
    },
    {
      value: '430200',
      children: [],
      label: '株洲市',
      level: 2
    },
    {
      value: '430300',
      children: [],
      label: '湘潭市',
      level: 2
    },
    {
      value: '430400',
      children: [],
      label: '衡阳市',
      level: 2
    },
    {
      value: '430500',
      children: [],
      label: '邵阳市',
      level: 2
    },
    {
      value: '430600',
      children: [],
      label: '岳阳市',
      level: 2
    },
    {
      value: '430700',
      children: [],
      label: '常德市',
      level: 2
    },
    {
      value: '430800',
      children: [],
      label: '张家界市',
      level: 2
    },
    {
      value: '430900',
      children: [],
      label: '益阳市',
      level: 2
    },
    {
      value: '431000',
      children: [],
      label: '郴州市',
      level: 2
    },
    {
      value: '431100',
      children: [],
      label: '永州市',
      level: 2
    },
    {
      value: '431200',
      children: [],
      label: '怀化市',
      level: 2
    },
    {
      value: '431300',
      children: [],
      label: '娄底市',
      level: 2
    },
    {
      value: '433100',
      children: [],
      label: '湘西土家族苗族自治州',
      level: 2
    }],
    label: '湖南省',
    level: 1
  },
  {
    value: '440000',
    children: [{
      value: '440100',
      children: [],
      label: '广州市',
      level: 2
    },
    {
      value: '440200',
      children: [],
      label: '韶关市',
      level: 2
    },
    {
      value: '440300',
      children: [],
      label: '深圳市',
      level: 2
    },
    {
      value: '440400',
      children: [],
      label: '珠海市',
      level: 2
    },
    {
      value: '440500',
      children: [],
      label: '汕头市',
      level: 2
    },
    {
      value: '440600',
      children: [],
      label: '佛山市',
      level: 2
    },
    {
      value: '440700',
      children: [],
      label: '江门市',
      level: 2
    },
    {
      value: '440800',
      children: [],
      label: '湛江市',
      level: 2
    },
    {
      value: '440900',
      children: [],
      label: '茂名市',
      level: 2
    },
    {
      value: '441200',
      children: [],
      label: '肇庆市',
      level: 2
    },
    {
      value: '441300',
      children: [],
      label: '惠州市',
      level: 2
    },
    {
      value: '441400',
      children: [],
      label: '梅州市',
      level: 2
    },
    {
      value: '441500',
      children: [],
      label: '汕尾市',
      level: 2
    },
    {
      value: '441600',
      children: [],
      label: '河源市',
      level: 2
    },
    {
      value: '441700',
      children: [],
      label: '阳江市',
      level: 2
    },
    {
      value: '441800',
      children: [],
      label: '清远市',
      level: 2
    },
    {
      value: '441900',
      children: [],
      label: '东莞市',
      level: 2
    },
    {
      value: '442000',
      children: [],
      label: '中山市',
      level: 2
    },
    {
      value: '445100',
      children: [],
      label: '潮州市',
      level: 2
    },
    {
      value: '445200',
      children: [],
      label: '揭阳市',
      level: 2
    },
    {
      value: '445300',
      children: [],
      label: '云浮市',
      level: 2
    }],
    label: '广东省',
    level: 1
  },
  {
    value: '450000',
    children: [{
      value: '450100',
      children: [],
      label: '南宁市',
      level: 2
    },
    {
      value: '450200',
      children: [],
      label: '柳州市',
      level: 2
    },
    {
      value: '450300',
      children: [],
      label: '桂林市',
      level: 2
    },
    {
      value: '450400',
      children: [],
      label: '梧州市',
      level: 2
    },
    {
      value: '450500',
      children: [],
      label: '北海市',
      level: 2
    },
    {
      value: '450600',
      children: [],
      label: '防城港市',
      level: 2
    },
    {
      value: '450700',
      children: [],
      label: '钦州市',
      level: 2
    },
    {
      value: '450800',
      children: [],
      label: '贵港市',
      level: 2
    },
    {
      value: '450900',
      children: [],
      label: '玉林市',
      level: 2
    },
    {
      value: '451000',
      children: [],
      label: '百色市',
      level: 2
    },
    {
      value: '451100',
      children: [],
      label: '贺州市',
      level: 2
    },
    {
      value: '451200',
      children: [],
      label: '河池市',
      level: 2
    },
    {
      value: '451300',
      children: [],
      label: '来宾市',
      level: 2
    },
    {
      value: '451400',
      children: [],
      label: '崇左市',
      level: 2
    }],
    label: '广西壮族自治区',
    level: 1
  },
  {
    value: '460000',
    children: [{
      value: '460100',
      children: [],
      label: '海口市',
      level: 2
    },
    {
      value: '460200',
      children: [],
      label: '三亚市',
      level: 2
    },
    {
      value: '460300',
      children: [],
      label: '三沙市',
      level: 2
    },
    {
      value: '460400',
      children: [],
      label: '儋州市',
      level: 2
    },
    {
      value: '469001',
      children: [],
      label: '五指山市',
      level: 2
    },
    {
      value: '469002',
      children: [],
      label: '琼海市',
      level: 2
    },
    {
      value: '469005',
      children: [],
      label: '文昌市',
      level: 2
    },
    {
      value: '469006',
      children: [],
      label: '万宁市',
      level: 2
    },
    {
      value: '469007',
      children: [],
      label: '东方市',
      level: 2
    },
    {
      value: '469021',
      children: [],
      label: '定安县',
      level: 2
    },
    {
      value: '469022',
      children: [],
      label: '屯昌县',
      level: 2
    },
    {
      value: '469023',
      children: [],
      label: '澄迈县',
      level: 2
    },
    {
      value: '469024',
      children: [],
      label: '临高县',
      level: 2
    },
    {
      value: '469025',
      children: [],
      label: '白沙黎族自治县',
      level: 2
    },
    {
      value: '469026',
      children: [],
      label: '昌江黎族自治县',
      level: 2
    },
    {
      value: '469027',
      children: [],
      label: '乐东黎族自治县',
      level: 2
    },
    {
      value: '469028',
      children: [],
      label: '陵水黎族自治县',
      level: 2
    },
    {
      value: '469029',
      children: [],
      label: '保亭黎族苗族自治县',
      level: 2
    },
    {
      value: '469030',
      children: [],
      label: '琼中黎族苗族自治县',
      level: 2
    }],
    label: '海南省',
    level: 1
  },
  {
    value: '500000',
    children: [{
      value: '500100',
      children: [],
      label: '重庆市',
      level: 2
    }],
    label: '重庆',
    level: 1
  },
  {
    value: '510000',
    children: [{
      value: '510100',
      children: [],
      label: '成都市',
      level: 2
    },
    {
      value: '510300',
      children: [],
      label: '自贡市',
      level: 2
    },
    {
      value: '510400',
      children: [],
      label: '攀枝花市',
      level: 2
    },
    {
      value: '510500',
      children: [],
      label: '泸州市',
      level: 2
    },
    {
      value: '510600',
      children: [],
      label: '德阳市',
      level: 2
    },
    {
      value: '510700',
      children: [],
      label: '绵阳市',
      level: 2
    },
    {
      value: '510800',
      children: [],
      label: '广元市',
      level: 2
    },
    {
      value: '510900',
      children: [],
      label: '遂宁市',
      level: 2
    },
    {
      value: '511000',
      children: [],
      label: '内江市',
      level: 2
    },
    {
      value: '511100',
      children: [],
      label: '乐山市',
      level: 2
    },
    {
      value: '511300',
      children: [],
      label: '南充市',
      level: 2
    },
    {
      value: '511400',
      children: [],
      label: '眉山市',
      level: 2
    },
    {
      value: '511500',
      children: [],
      label: '宜宾市',
      level: 2
    },
    {
      value: '511600',
      children: [],
      label: '广安市',
      level: 2
    },
    {
      value: '511700',
      children: [],
      label: '达州市',
      level: 2
    },
    {
      value: '511800',
      children: [],
      label: '雅安市',
      level: 2
    },
    {
      value: '511900',
      children: [],
      label: '巴中市',
      level: 2
    },
    {
      value: '512000',
      children: [],
      label: '资阳市',
      level: 2
    },
    {
      value: '513200',
      children: [],
      label: '阿坝藏族羌族自治州',
      level: 2
    },
    {
      value: '513300',
      children: [],
      label: '甘孜藏族自治州',
      level: 2
    },
    {
      value: '513400',
      children: [],
      label: '凉山彝族自治州',
      level: 2
    }],
    label: '四川省',
    level: 1
  },
  {
    value: '520000',
    children: [{
      value: '520100',
      children: [],
      label: '贵阳市',
      level: 2
    },
    {
      value: '520200',
      children: [],
      label: '六盘水市',
      level: 2
    },
    {
      value: '520300',
      children: [],
      label: '遵义市',
      level: 2
    },
    {
      value: '520400',
      children: [],
      label: '安顺市',
      level: 2
    },
    {
      value: '520500',
      children: [],
      label: '毕节市',
      level: 2
    },
    {
      value: '520600',
      children: [],
      label: '铜仁市',
      level: 2
    },
    {
      value: '522300',
      children: [],
      label: '黔西南布依族苗族自治州',
      level: 2
    },
    {
      value: '522600',
      children: [],
      label: '黔东南苗族侗族自治州',
      level: 2
    },
    {
      value: '522700',
      children: [],
      label: '黔南布依族苗族自治州',
      level: 2
    }],
    label: '贵州省',
    level: 1
  },
  {
    value: '530000',
    children: [{
      value: '530100',
      children: [],
      label: '昆明市',
      level: 2
    },
    {
      value: '530300',
      children: [],
      label: '曲靖市',
      level: 2
    },
    {
      value: '530400',
      children: [],
      label: '玉溪市',
      level: 2
    },
    {
      value: '530500',
      children: [],
      label: '保山市',
      level: 2
    },
    {
      value: '530600',
      children: [],
      label: '昭通市',
      level: 2
    },
    {
      value: '530700',
      children: [],
      label: '丽江市',
      level: 2
    },
    {
      value: '530800',
      children: [],
      label: '普洱市',
      level: 2
    },
    {
      value: '530900',
      children: [],
      label: '临沧市',
      level: 2
    },
    {
      value: '532300',
      children: [],
      label: '楚雄彝族自治州',
      level: 2
    },
    {
      value: '532500',
      children: [],
      label: '红河哈尼族彝族自治州',
      level: 2
    },
    {
      value: '532600',
      children: [],
      label: '文山壮族苗族自治州',
      level: 2
    },
    {
      value: '532800',
      children: [],
      label: '西双版纳傣族自治州',
      level: 2
    },
    {
      value: '532900',
      children: [],
      label: '大理白族自治州',
      level: 2
    },
    {
      value: '533100',
      children: [],
      label: '德宏傣族景颇族自治州',
      level: 2
    },
    {
      value: '533300',
      children: [],
      label: '怒江傈僳族自治州',
      level: 2
    },
    {
      value: '533400',
      children: [],
      label: '迪庆藏族自治州',
      level: 2
    }],
    label: '云南省',
    level: 1
  },
  {
    value: '540000',
    children: [{
      value: '540100',
      children: [],
      label: '拉萨市',
      level: 2
    },
    {
      value: '540200',
      children: [],
      label: '日喀则市',
      level: 2
    },
    {
      value: '540300',
      children: [],
      label: '昌都市',
      level: 2
    },
    {
      value: '540400',
      children: [],
      label: '林芝市',
      level: 2
    },
    {
      value: '540500',
      children: [],
      label: '山南市',
      level: 2
    },
    {
      value: '540600',
      children: [],
      label: '那曲市',
      level: 2
    },
    {
      value: '542500',
      children: [],
      label: '阿里地区',
      level: 2
    }],
    label: '西藏自治区',
    level: 1
  },
  {
    value: '610000',
    children: [{
      value: '610100',
      children: [],
      label: '西安市',
      level: 2
    },
    {
      value: '610200',
      children: [],
      label: '铜川市',
      level: 2
    },
    {
      value: '610300',
      children: [],
      label: '宝鸡市',
      level: 2
    },
    {
      value: '610400',
      children: [],
      label: '咸阳市',
      level: 2
    },
    {
      value: '610500',
      children: [],
      label: '渭南市',
      level: 2
    },
    {
      value: '610600',
      children: [],
      label: '延安市',
      level: 2
    },
    {
      value: '610700',
      children: [],
      label: '汉中市',
      level: 2
    },
    {
      value: '610800',
      children: [],
      label: '榆林市',
      level: 2
    },
    {
      value: '610900',
      children: [],
      label: '安康市',
      level: 2
    },
    {
      value: '611000',
      children: [],
      label: '商洛市',
      level: 2
    }],
    label: '陕西省',
    level: 1
  },
  {
    value: '620000',
    children: [{
      value: '620100',
      children: [],
      label: '兰州市',
      level: 2
    },
    {
      value: '620200',
      children: [],
      label: '嘉峪关市',
      level: 2
    },
    {
      value: '620300',
      children: [],
      label: '金昌市',
      level: 2
    },
    {
      value: '620400',
      children: [],
      label: '白银市',
      level: 2
    },
    {
      value: '620500',
      children: [],
      label: '天水市',
      level: 2
    },
    {
      value: '620600',
      children: [],
      label: '武威市',
      level: 2
    },
    {
      value: '620700',
      children: [],
      label: '张掖市',
      level: 2
    },
    {
      value: '620800',
      children: [],
      label: '平凉市',
      level: 2
    },
    {
      value: '620900',
      children: [],
      label: '酒泉市',
      level: 2
    },
    {
      value: '621000',
      children: [],
      label: '庆阳市',
      level: 2
    },
    {
      value: '621100',
      children: [],
      label: '定西市',
      level: 2
    },
    {
      value: '621200',
      children: [],
      label: '陇南市',
      level: 2
    },
    {
      value: '622900',
      children: [],
      label: '临夏回族自治州',
      level: 2
    },
    {
      value: '623000',
      children: [],
      label: '甘南藏族自治州',
      level: 2
    }],
    label: '甘肃省',
    level: 1
  },
  {
    value: '630000',
    children: [{
      value: '630100',
      children: [],
      label: '西宁市',
      level: 2
    },
    {
      value: '630200',
      children: [],
      label: '海东市',
      level: 2
    },
    {
      value: '632200',
      children: [],
      label: '海北藏族自治州',
      level: 2
    },
    {
      value: '632300',
      children: [],
      label: '黄南藏族自治州',
      level: 2
    },
    {
      value: '632500',
      children: [],
      label: '海南藏族自治州',
      level: 2
    },
    {
      value: '632600',
      children: [],
      label: '果洛藏族自治州',
      level: 2
    },
    {
      value: '632700',
      children: [],
      label: '玉树藏族自治州',
      level: 2
    },
    {
      value: '632800',
      children: [],
      label: '海西蒙古族藏族自治州',
      level: 2
    }],
    label: '青海省',
    level: 1
  },
  {
    value: '640000',
    children: [{
      value: '640100',
      children: [],
      label: '银川市',
      level: 2
    },
    {
      value: '640200',
      children: [],
      label: '石嘴山市',
      level: 2
    },
    {
      value: '640300',
      children: [],
      label: '吴忠市',
      level: 2
    },
    {
      value: '640400',
      children: [],
      label: '固原市',
      level: 2
    },
    {
      value: '640500',
      children: [],
      label: '中卫市',
      level: 2
    }],
    label: '宁夏回族自治区',
    level: 1
  },
  {
    value: '710000',
    children: [{
      value: '710100',
      children: [],
      label: '台北市',
      level: 2
    },
    {
      value: '710200',
      children: [],
      label: '高雄市',
      level: 2
    },
    {
      value: '710300',
      children: [],
      label: '基隆市',
      level: 2
    },
    {
      value: '710400',
      children: [],
      label: '台中市',
      level: 2
    },
    {
      value: '710500',
      children: [],
      label: '台南市',
      level: 2
    },
    {
      value: '710600',
      children: [],
      label: '新竹市',
      level: 2
    },
    {
      value: '710700',
      children: [],
      label: '嘉义市',
      level: 2
    },
    {
      value: '710800',
      children: [],
      label: '新北市',
      level: 2
    },
    {
      value: '712200',
      children: [],
      label: '宜兰县',
      level: 2
    },
    {
      value: '712300',
      children: [],
      label: '桃园市',
      level: 2
    },
    {
      value: '712400',
      children: [],
      label: '新竹县',
      level: 2
    },
    {
      value: '712500',
      children: [],
      label: '苗栗县',
      level: 2
    },
    {
      value: '712700',
      children: [],
      label: '彰化县',
      level: 2
    },
    {
      value: '712800',
      children: [],
      label: '南投县',
      level: 2
    },
    {
      value: '712900',
      children: [],
      label: '云林县',
      level: 2
    },
    {
      value: '713000',
      children: [],
      label: '嘉义县',
      level: 2
    },
    {
      value: '713300',
      children: [],
      label: '屏东县',
      level: 2
    },
    {
      value: '713400',
      children: [],
      label: '台东县',
      level: 2
    },
    {
      value: '713500',
      children: [],
      label: '花莲县',
      level: 2
    },
    {
      value: '713600',
      children: [],
      label: '澎湖县',
      level: 2
    },
    {
      value: '713700',
      children: [],
      label: '金门县',
      level: 2
    },
    {
      value: '713800',
      children: [],
      label: '连江县',
      level: 2
    }],
    label: '台湾',
    level: 1
  },
  {
    value: '810000',
    children: [{
      value: '810100',
      children: [],
      label: '香港岛',
      level: 2
    },
    {
      value: '810200',
      children: [],
      label: '九龙',
      level: 2
    },
    {
      value: '810300',
      children: [],
      label: '新界',
      level: 2
    }],
    label: '香港特别行政区',
    level: 1
  },
  {
    value: '820000',
    children: [{
      value: '820100',
      children: [],
      label: '澳门半岛',
      level: 2
    },
    {
      value: '820200',
      children: [],
      label: '氹仔岛',
      level: 2
    },
    {
      value: '820300',
      children: [],
      label: '路环岛',
      level: 2
    }],
    label: '澳门特别行政区',
    level: 1
  },
  {
    value: '900000',
    children: [],
    label: '钓鱼岛',
    level: 1
  }]
