<!--
 * @Description: 用一句话描述文件的作用
 * @Author: Adlj
 * @Date: 2022-10-13 16:44:54
 * @LastEditors: Adlj
 * @LastEditTime: 2022-10-14 13:30:34
-->
<template>
  <el-image :src="`${realSrc}`"
            fit="cover"
            :style="`width:${realWidth};height:${realHeight};`"
            :preview-src-list="realSrcList">
    <div slot="error"
         class="image-slot">
      <i class="el-icon-picture-outline"></i>
    </div>
  </el-image>
</template>

<script>
import { isExternal } from '@/utils/validate'
import setting from '@/setting'
export default {
  name: 'ImagePreview',
  props: {
    src: {
      type: String,
      default: ''
    },
    width: {
      type: [Number, String],
      default: ''
    },
    height: {
      type: [Number, String],
      default: ''
    }
  },
  data () {
    return {
      baseUrl: setting.server.baseUrl
    }
  },
  computed: {
    realSrc () {
      if (!this.src) {
        return
      }
      const real_src = this.src.split(',')[0]
      if (isExternal(real_src)) {
        return real_src
      }
      return this.baseUrl + real_src
    },
    realSrcList () {
      if (!this.src) {
        return
      }
      const real_src_list = this.src.split(',')
      const srcList = []
      real_src_list.forEach(item => {
        if (isExternal(item)) {
          return srcList.push(item)
        }
        return srcList.push(this.baseUrl + item)
      })
      return srcList
    },
    realWidth () {
      return typeof this.width === 'string' ? this.width : `${this.width}px`
    },
    realHeight () {
      return typeof this.height === 'string' ? this.height : `${this.height}px`
    }
  }
}
</script>

<style lang="scss" scoped>
.el-image {
  border-radius: 5px;
  background-color: #ebeef5;
  box-shadow: 0 0 5px 1px #ccc;
  ::v-deep .el-image__inner {
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      transform: scale(1.2);
    }
  }
  ::v-deep .image-slot {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #909399;
    font-size: 30px;
  }
}
</style>
