/*
 * @Description: 路由配置文件
 * @Author: Pancras
 * @Date: 2022-06-27 13:30:07
 * @LastEditors: Pancras
 * @LastEditTime: 2022-10-13 17:53:33
 */
import FrontLayout from '@/layout/front/index'
import BackendLayout from '@/layout/backend/index'

const routes = [
  {
    path: '/',
    redirect: { name: 'index' },
    component: FrontLayout,
    children: [
      // 首页
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/index'),
        meta: {
          title: '首页'
        }
      },
      {
        path: 'species',
        name: 'species',
        component: () => import('@/views/species'),
        meta: {
          title: '物种名录'
        }
      },
      {
        path: 'data-collection',
        name: 'data-collection',
        component: () => import('@/views/data-collection'),
        meta: {
          title: '踏查数据 '
        }
      },
      {
        path: 'speciesdetail',
        name: 'speciesdetail',
        redirect: { name: 'summarize' },
        component: () => import('@/views/species/detail/index'),
        meta: {
          title: '物种名录'
        },

        children: [
          {
            path: 'summarize',
            name: 'summarize',
            component: () => import('@/views/species/detail/summarize'),
            meta: {
              title: '概述'
            }
          },
          {
            path: 'treatRecord',
            name: 'treatRecord',
            component: () => import('@/views/species/detail/treatRecord'),
            meta: {
              title: '治理记录'
            }
          },
          {
            path: 'record',
            name: 'record',
            component: () => import('@/views/species/detail/record'),
            meta: {
              title: '入侵记录'
            }
          },
          {
            path: 'relatedData',
            name: 'relatedData',
            component: () => import('@/views/species/detail/relatedData'),
            meta: {
              title: '相关资料'
            }
          }
        ]
      },
      {
        path: 'recordDetail',
        name: 'recordDetail',
        component: () => import('@/views/species/detail/record/recordDetail'),
        meta: {
          title: '入侵记录'
        }
      },
      {
        path: 'relateDetail',
        name: 'relateDetail',
        component: () => import('@/views/species/detail/relatedData/relateDetail'),
        meta: {
          title: '相关资料'
        }
      },
      {
        path: 'treatDetail',
        name: 'treatDetail',
        component: () => import('@/views/species/detail/treatRecord/recordDetail'),
        meta: {
          title: '治理记录'
        }
      },
      {
        path: 'distribute',
        name: 'distribute',
        component: () => import('@/views/distribute'),
        meta: {
          title: '监测预警'
        }
      },
      {
        path: 'news',
        name: 'news',
        component: () => import('@/views/news'),
        meta: {
          title: '新闻公告'
        }
      },
      {
        path: 'newsDetail',
        name: 'newsDetail',
        component: () => import('@/views/news/detail'),
        meta: {
          title: '新闻公告'
        }
      }
    ]
  },
  {
    path: '/uc',
    redirect: { name: 'home' },
    component: BackendLayout,
    children: [
      // 个人中心
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/backend/index')
      },
      // 会员资料
      {
        path: 'user',
        name: 'user',
        component: () => import('@/views/backend/userInfo')
      },
      // 修改密码
      {
        path: 'safe',
        name: 'safe',
        component: () => import('@/views/backend/userSafe')
      },
      // 入侵记录
      {
        path: 'invasionRecord',
        name: 'invasionRecord',
        component: () => import('@/views/backend/invasionRecord')
      },
      // 治理记录
      {
        path: 'governanceRecord',
        name: 'governanceRecord',
        component: () => import('@/views/backend/governanceRecord')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404/index'),
    meta: {
      title: '404'
    }
  }
]

export default routes
