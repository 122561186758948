/*
 * @Description: 系统配置文件
 * @Author: Pancras
 * @Date: 2019-09-29 10:44:30
 * @LastEditors: Adlj
 * @LastEditTime: 2022-10-13 13:59:17
 */
export default {
  webSite: {
    // title: '外来物种入侵查询',
    logo: '',
    tel: '',
    copyright: '© 2022～2044 自治区农业生态与自然保护站乌鲁木齐市天山区胜利路153号新农大厦',
    support: '新疆外来入侵物种数据中心'
  },
  // 服务器配置
  server: {
    // 定义 BASE_URL
    baseUrl: 'http://alien.lztl.cn/prod-api',
    // 加密用 key 应和服务器保持一致
    encryptionKey: 'asspasspasspassp',
    // 租户ID
    tenantId: '1',
    // 认证中心加密字符串，应和数据库配置的的客户端保持一致
    authorization: 'Basic YXdhdDphd2F0'
  }
}
