<!--
 * @Description: 前台页面布局
 * @Author: Pancras
 * @Date: 2022-06-27 12:21:45
 * @LastEditors: Adlj
 * @LastEditTime: 2022-10-14 16:15:11
-->
<template>
  <div>
    <!-- Header 开始 -->
    <header class="header">
      <div class="top-bar"
           flex="dir:left main:justify">
        <div class="top-bar-left"
             flex="main:left cross:center">
          <img src="@/assets/index/logo.png">
          <div>
            <div class="top">农业外来入侵物种普查数据库信息平台</div>
            <div class="bottom">XinJiang Alien Invasive Species Data Center</div>
          </div>
        </div>

        <div class="top-bar-right"
             flex="main:left cross:center">
          <div class="labelBtn"
               flex="main:left cross:center">
            <router-link to="/index"
                         active-class="btn1">
              <div @click="onBtn(0)">首页</div>
            </router-link>

            <router-link to="/species"
                         active-class="btn1">
              <div @click="onBtn(1)">物种名录</div>
            </router-link>

            <router-link to="/distribute"
                         active-class="btn1">
              <div @click="onBtn(2)">监测预警</div>
            </router-link>

            <!-- <router-link to="/data-collection"
                         active-class="btn1">
              <div @click="onBtn(3)">普查数据</div>
            </router-link> -->

            <router-link to="/news"
                         active-class="btn1">
              <div @click="onBtn(4)">新闻公告</div>
            </router-link>
          </div>

          <div>
            <div v-if="!logState"
                 class="logBtn"
                 @click="onLog">
              登录
            </div>
            <div v-if="logState"
                 class="user"
                 flex="main:left cross:center">
              <div class="head"
                   @click="goUc">
                <img :src="avatar"
                     alt=""
                     srcset="">
              </div>
              <div class="txt tl-ml-10">
                <span @click="goUc">{{name}}</span>
                <span @click="logout">【退出登录】</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- Header 结束 -->

    <main class="main">
      <router-view></router-view>
    </main>

    <footer class="footer"
            v-if="btnState===0">
      {{setting.webSite.copyright}}<br />
      {{setting.webSite.support}}<br />
      {{setting.webSite.tel}}
    </footer>
  </div>
</template>

<script>
import { getToken } from '@/libs/auth'
import setting from '@/setting'
export default {
  name: 'FrontLayout',
  data () {
    return {
      setting,
      btnState: 0, // 按钮索引
      logState: false, // 登录状态
      searchParams: null,
      avatar: null,
      name: null,
      baseUrl: setting.server.baseUrl
    }
  },
  mounted () {
    if (getToken()) {
      this.$store.dispatch('GetInfo').then(res => {
        const user = res.user
        const avatar = (user.avatar === '' || user.avatar == null) ? require('@/assets/user.png') : this.baseUrl + user.avatar
        this.avatar = avatar
        this.name = user.nickName
        this.logState = true
      })
    }
  },
  methods: {
    onBtn (state) {
      this.btnState = state
    },

    getMoreState (e) {
      if (e) {
        this.btnState = 1
      }
    },

    getSearchParams (params) {
      this.searchParams = params
      this.btnState = 1
    },
    goUc () {
      this.$router.push('/uc')
    },
    onLog () {
      this.$router.push('/login')
    },
    async logout () {
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          this.logState = false
        })
      }).catch(() => { })
    }
  }
}
</script>

<style lang="scss">
//引入样式
@import "~@/assets/style/layout/front.scss";
</style>
