/*
 * @Description: 用一句话描述文件的作用
 * @Author: Adlj
 * @Date: 2022-10-13 15:32:16
 * @LastEditors: Adlj
 * @LastEditTime: 2022-10-13 20:24:44
 */
import request from '@/libs/axios'

// 查询物种列表
export function listSpecies (query) {
  return request({
    url: '/prod-api/system/species/list',
    method: 'get',
    params: query
  })
}

// 查询物种详细
export function getSpecies (id) {
  return request({
    url: '/prod-api/system/species/' + id,
    method: 'get'
  })
}

// 查询物种详细[vo]
export function getSpeciesVo (id) {
  return request({
    url: '/prod-api/system/species/detail/' + id,
    method: 'get'
  })
}

// 新增物种
export function addSpecies (data) {
  return request({
    url: '/prod-api/system/species',
    method: 'post',
    data: data
  })
}

// 修改物种
export function updateSpecies (data) {
  return request({
    url: '/prod-api/system/species',
    method: 'put',
    data: data
  })
}

// 删除物种
export function delSpecies (id) {
  return request({
    url: '/prod-api/system/species/' + id,
    method: 'delete'
  })
}

// 查询上报未上报数据数量
export function getReportCount () {
  return request({
    url: '/prod-api/system/species/report/count',
    method: 'get'
  })
}
