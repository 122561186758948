<template>
  <el-cascader ref="area"
               v-if="loaded"
               v-model="checkedValue"
               :options="options"
               :props="props"
               :clearable="clearable"
               :placeholder="placeholder"
               :size="size"
               :disabled="forbidden"
               :showAllLevels="showAllLevels"
               :separator="separator"
               filterable
               :popperClass="popperClass"
               @change="handleChange"
  ></el-cascader>
</template>

<script>
import { _GetAreaByParent, _GetAreaTree } from '@/api/system/area' // 行政区划 API
import area from './data/area'// 县级及以上行政区划
import { isNull, isEqual } from '@/utils'
import _ from 'lodash'

export default {
  name: 'area-select',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {},
    // 区划等级 值:1-5
    maxLevel: {
      type: Number,
      default: 3
    },
    // 返回值类型:true: 各级value组成的数组 false:最后一级value
    emitPath: {
      type: Boolean,
      default: true
    },
    // 尺寸
    size: String,
    // 输入框占位文本
    placeholder: {
      type: String,
      default: '请选择行政区划'
    },
    // 是否禁用
    disabled: Boolean,
    // 是否支持清空选项
    clearable: Boolean,
    // 是否支持清空选项
    showAllLevels: {
      type: Boolean,
      default: true
    },
    // 是否可选择任意节点
    checkStrictly: {
      type: Boolean,
      default: false
    },
    // 是否悬浮展示下一级
    expandTrigger: {
      type: String,
      default: 'click'
    },
    // 选项分隔符
    separator: {
      type: String,
      default: ' / '
    },
    // 自定义浮层类名
    popperClass: String
  },
  data() {
    let _this = this
    return {
      loaded: false, // 加载状态
      checkedValue: null, // 检查后的 value
      options: [], // 前三级区划数据
      flatNodes: [], // 扁平化后的树
      forbidden: this.disabled, // 控件可用状态
      props: {
        expandTrigger: this.expandTrigger,
        checkStrictly: this.checkStrictly,
        emitPath: this.emitPath,
        lazy: true, // 动态加载
        lazyLoad: (selectedNode, resolve) => {
          const { value, children, level } = selectedNode
          // 前三级本地获取子节点
          if (parseInt(level) < 3) {
            resolve(null)
          } else {
            let _children = children
            if (isNull(children)) {
              // 后台获取下级节点
              _GetAreaByParent(value).then(res => {
                _children = res.data.map(item => {
                  return {
                    value: item.id,
                    label: item.name,
                    level: item.levelType,
                    lng: item.lng,
                    lat: item.lat,
                    leaf: parseInt(item.levelType) === _this.maxLevel // 设置为叶子节点
                  }
                })
                resolve(_children)
              })
            } else {
              resolve(null)
            }
          }
        }
      }
    }
  },
  watch: {
    value: {
      handler(val) {
        this.loaded = false
        this.syncCheckedValue()
        this.initData()
      },
      immediate: true
    }
  },
  methods: {
    /**
     * initData
     * @description 初始化
     */
    initData() {
      const { checkedValue, maxLevel } = this
      this.options = area// 每次初始化重新赋值
      this.forbidden = checkedValue === '100000'// 若节点为 1000 ,禁止编辑
      this.setLeafByLevel(maxLevel)// 设置叶子节点
      if (!isNull(checkedValue)) {
        let levelTree = this.getLevelThree()// 获取第三级区划代码
        if (!isNull(levelTree) && this.maxLevel > 3) {
          this.appendTreeNode(levelTree)// 添加用户已选择的 5.6 级数据
        }
      }
      this.loaded = true
    },

    /**
     * syncCheckedValue
     * @description     根据 value 获取 checkedValue
     */
    syncCheckedValue() {
      this.checkedValue = null
      const { value, checkedValue } = this
      if (!isEqual(value, checkedValue)) {
        // value 是数组
        if (Array.isArray(value)) {
          if (value.length > this.maxLevel) {
            this.checkedValue = value.slice(0, this.maxLevel)
          } else {
            this.checkedValue = value
          }
        } else {
          // value 是字符串
          switch (this.maxLevel) {
            case 1:
              this.checkedValue = value.length > 2 ? value.substring(0, 2) + '0000' : value
              break
            case 2:
              this.checkedValue = value.length > 4 ? value.substring(0, 4) + '00' : value
              break
            case 3:
              this.checkedValue = value.length > 6 ? value.substring(0, 6) : value
              break
            default:
              this.checkedValue = value
          }
        }
      }
    },

    /**
     * getLevelThree
     * @description     获取第三极行政区划编码
     * @param {String}  区划编码
     */
    getLevelThree() {
      let checkedValue = _.cloneDeep(this.checkedValue)
      if (Array.isArray(checkedValue)) {
        let value = checkedValue.pop() // 获取最后一个元素
        return value.length >= 6 ? value.substring(0, 6) : ''
      } else {
        return checkedValue.length >= 6 ? checkedValue.substring(0, 6) : ''
      }
    },

    /**
     * appendTreeNode
     * @description  添加用户已选择的 5,6 级行政区划
     * @param {JsonTree}  后台获取的树形区划数据
     */
    appendTreeNode(levelTree) {
      let node = this.getTreeNodeByCode(levelTree)// 获取第三极节点
      // 后台获取 4,5 级行政区划树
      _GetAreaTree(levelTree, this.maxLevel).then(res => {
        let _children = this.treeNodeFormat(res.data)
        if (_children && _children.length > 0) {
          node.leaf = false
          node.children.splice(0, node.children.length, ..._children)// 清空并追加子节点
          // node.children.splice(0, node.children.length)// 清空子节点
          // node.children.push(..._children)// 添加动态节点
        } else {
          node.leaf = true
        }
      })
    },

    /**
     * treeNodeFormat
     * @description  将后台获取的树形区划数据格式化为 为 Cascader 标准格式
     * @param {JsonTree}  后台获取的树形区划数据
     */
    treeNodeFormat(ajaxTreeNodes) {
      let cascaderNodes = []
      ajaxTreeNodes.forEach((item) => {
        let node = {
          value: item.id,
          label: item.name,
          level: item.level,
          lng: item.lng,
          lat: item.lat,
          leaf: !(item.children && item.children.length > 0)
        }
        if (item.children && item.children.length > 0) {
          node.children = this.treeNodeFormat(item.children)
        }
        cascaderNodes.push(node)
      })
      return cascaderNodes
    },

    /**
     * getTreeNodeByCode
     * @description  根据区划编码递归区划树获取当前区划
     * @param {String}  区划编码
     */
    getTreeNodeByCode(value) {
      const _area = this.options
      let treeNode = []
      let getNode = (items, value) => {
        items.some(item => {
          if (value === item.value) {
            treeNode = item
            return true
          } else if (item.children && item.children.length > 0) {
            getNode(item.children, value)
          }
        })
      }
      getNode(_area, value)
      return treeNode
    },

    /**
     * setLeafByLevel
     * @description  设置 1,2 级叶子节点
     * @param {String}  区划编码
     */
    setLeafByLevel(level) {
      let _this = this
      const area = this.options
      let setLeaf = function(items, level) {
        items.forEach(item => {
          if (item.level === 3 && _this.maxLevel === 3) {
            item.leaf = true
          }
          if (item.level < 3) {
            if (level === item.level || (item.children && item.children.length === 0)) {
              item.leaf = true
            } else {
              item.leaf = false
              if (item.children && item.children.length > 0) {
                setLeaf(item.children, level)
              }
            }
          }
        })
      }
      setLeaf(area, level)
    },

    /**
     * handleChange
     * @description   将用户选择的区划返回给父组件
     * @param {Array}  区划编码
     */
    handleChange(value) {
      this.$emit('change', value)
      this.$emit('node-change', this.$refs.area.getCheckedNodes()[0])
    }
  }
}
</script>
