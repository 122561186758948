<!--
 * @Description: 用户中心菜单组件
 * @Author: Pancras
 * @Date: 2022-07-04 16:09:02
 * @LastEditors: Adlj
 * @LastEditTime: 2022-10-13 14:05:28
-->
<template>
  <div class="menu-container">
    <div class="title-bar"
         flex="box:justify main:center cross:center">
      <div class="l-icon">
        <img src="~@/assets/uc/user.png"
             alt="">
      </div>
      <div class="title">
        个人中心
      </div>
      <!-- <div class="r-icon">
        <router-link to="message">
          <el-badge :value="0"
                    class="item"
                    type="success">
            <img src="~@/assets/uc/email.png"
                 alt="">
          </el-badge>
        </router-link>
      </div> -->
    </div>
    <div class="nav-container tl-mt-30">
      <ul class="nav">
        <li>
          <router-link to="/uc/home">首页</router-link>
        </li>
      </ul>
      <!-- <ul class="nav">
        <li><a href="JavaScript:">认证申报</a></li>
        <li>
          <router-link to="/uc/apply?id=5">CCIA认证</router-link>
        </li>
        <li>
          <router-link to="/uc/apply?id=6">阿瓦提认证</router-link>
        </li>
        <li>
          <router-link to="/uc/apply?id=13">商标授权</router-link>
        </li>
        <li>
          <router-link to="/uc/authentication">其他认证</router-link>
        </li>
        <li>
          <router-link to="/uc/history">申请记录</router-link>
        </li>
      </ul> -->
      <ul class="nav">
        <li><a href="JavaScript:">数据上报</a></li>
        <li>
          <router-link to="/uc/governanceRecord">治理记录</router-link>
        </li>
        <li>
          <router-link to="/uc/invasionRecord">入侵记录</router-link>
        </li>
      </ul>
      <ul class="nav">
        <li><a href="">账户管理</a></li>
        <li>
          <router-link to="/uc/user">会员资料</router-link>
        </li>
        <li>
          <router-link to="/uc/safe">修改密码</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuItem'
}
</script>

<style lang="scss" scoped>
.menu-container {
  background-color: #fff;

  .l-icon {
    width: 20px;
    height: 20px;
  }

  .title-bar {
    padding: 20px 35px;
    margin: 0 auto;
    border-bottom: 1px solid $color-primary;

    .title {
      width: 80px;
      font-size: 20px;
      font-weight: 600;
      color: #031a12;
      margin-left: 10px;
    }

    .r-icon {
      margin-top: 10px;
    }
  }

  .nav-container {
    .nav {
      li {
        position: relative;
        list-style: none;
        height: 24px;
        font-size: 14px;
        font-weight: 400;
        color: #323333;
        line-height: 24px;

        a {
          color: #323333;
        }

        &:first-child {
          font-size: 16px;
          font-weight: 600;
          color: #031a12;
          line-height: 24px;

          &::before {
            content: "";
            position: absolute;
            top: 8px;
            left: -10px;
            width: 6px;
            height: 6px;
            background: $color-primary;
            border-radius: 50%;
          }
        }

        &:not(:first-child) {
          margin-left: 1em;
        }
      }
    }
  }
}
</style>
