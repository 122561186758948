<!--
 * @Description: 用户基本信息组件
 * @Author: Pancras
 * @Date: 2022-07-04 16:09:02
 * @LastEditors: Adlj
 * @LastEditTime: 2022-10-14 17:29:35
-->
<template>
  <div class="u-i-container"
       flex="dir:top">
    <div class="sign">{{user&&user.dept&&user.dept.deptName}}</div>
    <div class="avatar"
         flex="dir:top main:center cross:center">
      <img :src="user.avatar?setting.server.baseUrl + user.avatar:'/images/user.png'">
    </div>
    <div class="row">用户名：{{ user.nickName || user.userName }}</div>
    <!-- <div class="row"
         flex="cross:center">
      <div>安全等级：</div>
      <el-progress :percentage="80"
                   :color="'#41a45c'"
                   :show-text="false"
                   style="width:48%;"></el-progress>

      <span class="tl-ml-5">较好</span>
    </div> -->
    <div class="row"
         v-if="user.roles">用户角色：<span>{{userRole}}</span></div>
    <div class="row"
         v-if="user.roles">上次登录：<span>{{parseTime(user.loginDate, '{y}-{m}-{d} {h}:{m}')}}</span></div>
    <div class="bottom"
         flex="box:mean">
      <div>
        <div flex="dir:top cross:center">
          <span class="num">{{count&&count.allCount || 0}}</span>
          <span>全部</span>
        </div>
      </div>

      <div>
        <div flex="dir:top cross:center">
          <span class="num">{{count&&count.reportCount || 0}}</span>
          <span>已上报</span>
        </div>
      </div>
      <div flex="dir:top cross:center">
        <span class="num">{{count&&count.noReportCount || 0}}</span>
        <span>待上报</span>
      </div>
    </div>
  </div>
</template>

<script>
import setting from '@/setting'
import { getUserProfile } from '@/api/system/user'
import { getReportCount } from '@/api/species/species'
export default {
  name: 'UserInfoItem',
  data () {
    return {
      setting,
      user: {},
      roleGroup: {},
      postGroup: {},
      count: {}
    }
  },
  computed: {
    userRole () {
      const roles = []
      this.user && this.user.roles.forEach(role => {
        roles.push(role.roleName)
      })
      return roles.join(',')
    }
  },
  created () {
    this.getUser()
    this.getCount()
  },
  methods: {
    getUser () {
      getUserProfile().then(response => {
        this.user = response.data
        this.roleGroup = response.roleGroup
        this.postGroup = response.postGroup
      })
    },
    getCount () {
      getReportCount()
        .then(res => {
          this.count = res.data
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.u-i-container {
  background-color: #fff;
  position: relative;

  .sign {
    position: absolute;
    right: 0;
    top: 25px;
    width: 63px;
    height: 32px;
    background: $color-primary;
    border-radius: 16px 0px 0px 16px;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    line-height: 32px;
    text-align: center;
    box-shadow: 1px 1px 5px 0px $color-primary;
  }

  .avatar {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin: 25px auto;

    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin: 5px;
    }
  }

  .row {
    height: 14px;
    font-size: 14px;
    font-weight: 400;
    color: #323333;
    line-height: 20px;
    margin-top: 15px;
    margin-left: 24px;

    span {
      font-size: 14px;
      font-weight: 400;
      color: $color-primary;
      line-height: 20px;
    }
  }

  .bottom {
    margin: 30px 0 20px;

    span {
      font-size: 14px;
      font-family: SourceHanSansSC-Regular, SourceHanSansSC;
      font-weight: 400;
      color: #999999;
      line-height: 14px;
    }

    .num {
      font-size: 18px;
      font-family: SourceHanSansSC-Bold, SourceHanSansSC;
      font-weight: bold;
      color: #131415;
      line-height: 18px;
      margin-bottom: 10px;
    }
  }
}
</style>
