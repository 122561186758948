/*
 * @Description: Vuex 配置
 * @Author: Pancras
 * @Date: 2022-06-27 12:00:56
 * @LastEditors: Adlj
 * @LastEditTime: 2022-10-13 13:16:34
 */
import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

Vue.use(Vuex)

const modules = {}

// 获取 Modules 文件并转换为 Modules 配置
const files = require.context('./modules', false, /\.js$/)
files.keys().forEach(key => {
  modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
})

export default new Vuex.Store({
  modules: {
    ...modules
  },
  getters
})
