/*
 * @Description: 用一句话描述文件的作用
 * @Author: Adlj
 * @Date: 2022-10-13 13:11:32
 * @LastEditors: Adlj
 * @LastEditTime: 2022-10-13 13:17:01
 */
const getters = {
  // sidebar: state => state.app.sidebar,
  // size: state => state.app.size,
  // device: state => state.app.device,
  // dict: state => state.dict.dict,
  // visitedViews: state => state.tagsView.visitedViews,
  // cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name
  // introduction: state => state.user.introduction,
  // roles: state => state.user.roles,
  // permissions: state => state.user.permissions,
  // permission_routes: state => state.permission.routes
  // topbarRouters: state => state.permission.topbarRouters,
  // defaultRoutes: state => state.permission.defaultRoutes,
  // sidebarRouters: state => state.permission.sidebarRouters,
}
export default getters
