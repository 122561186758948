/*
 * @Description: 用一句话描述文件的作用
 * @Author: Adlj
 * @Date: 2022-10-12 17:59:06
 * @LastEditors: Adlj
 * @LastEditTime: 2022-10-12 18:03:32
 */
import request from '@/libs/axios'

// 登录方法
export function login (username, password, code, uuid) {
  const data = {
    username,
    password,
    code,
    uuid
  }
  return request({
    url: '/prod-api/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 注册方法
export function register (data) {
  return request({
    url: '/prod-api/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo () {
  return request({
    url: '/prod-api/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout () {
  return request({
    url: '/prod-api/logout',
    method: 'post'
  })
}

// 获取验证码
export function getCodeImg () {
  return request({
    url: '/prod-api/captchaImage',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}
