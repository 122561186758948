/*
 * @Description: 用一句话描述文件的作用
 * @Author: Adlj
 * @Date: 2022-10-13 16:18:43
 * @LastEditors: Adlj
 * @LastEditTime: 2022-10-13 16:19:24
 */
import request from '@/libs/axios'

// 查询字典数据列表
export function listData (query) {
  return request({
    url: '/prod-api/system/dict/data/list',
    method: 'get',
    params: query
  })
}

// 查询字典数据详细
export function getData (dictCode) {
  return request({
    url: '/prod-api/system/dict/data/' + dictCode,
    method: 'get'
  })
}

// 根据字典类型查询字典数据信息
export function getDicts (dictType) {
  return request({
    url: '/prod-api/system/dict/data/type/' + dictType,
    method: 'get'
  })
}

// 新增字典数据
export function addData (data) {
  return request({
    url: '/prod-api/system/dict/data',
    method: 'post',
    data: data
  })
}

// 修改字典数据
export function updateData (data) {
  return request({
    url: '/prod-api/system/dict/data',
    method: 'put',
    data: data
  })
}

// 删除字典数据
export function delData (dictCode) {
  return request({
    url: '/prod-api/system/dict/data/' + dictCode,
    method: 'delete'
  })
}
