/*
 * @Description: 行政区划接口文件
 * @Author: Pancras
 * @Date: 2019-10-21 10:13:41
 * @LastEditors: Adlj
 * @LastEditTime: 2022-10-13 17:58:55
 */
import request from '@/libs/axios'

/**
* @description 获取行政区划分页数据
* @param {Object} page 分页对象
* @param {Object} query 查询参数
*/
export function _GetAreaPage (page, query) {
  const params = { ...page, ...query }
  return request({
    url: '/prod-api/system/area/page',
    method: 'get',
    params: params
  })
}

/**
* @description 通过父级获取行政区划数据
* @param {Number} id 父级id
*/
export function _GetAreaByParent (id) {
  return request({
    url: '/prod-api/system/area/' + id + '/children',
    method: 'get'
  })
}

/**
* @description 添加行政区划数据
* @param {Object} obj 提交表单数据
*/
export function _AddArea (obj) {
  return request({
    url: '/prod-api/system/area',
    method: 'post',
    data: obj
  })
}

/**
* @description 通过id获取行政区划数据
* @param {Number} id
*/
export function _GetArea (id) {
  return request({
    url: '/prod-api/system/area/' + id,
    method: 'get'
  })
}

/**
* @description 通过id删除行政区划数据
* @param {Number} id
*/
export function _DelArea (id) {
  return request({
    url: '/prod-api/system/area/' + id,
    method: 'delete'
  })
}

/**
* @description 更新行政区划数据
* @param {Object} obj 提交表单数据
*/
export function _PutArea (obj) {
  return request({
    url: '/prod-api/system/area',
    method: 'put',
    data: obj
  })
}

/**
* @description 获取行政区划树数据
* @param {Number} parent 父级
* @param {Number} level 层级
*/
export function _GetAreaTree (parent, level) {
  return request({
    url: '/prod-api/system/area/tree/parent/' + parent + '/level/' + level,
    method: 'get'
  })
}
