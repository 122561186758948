/*
 * @Description: 路由配置文件
 * @Author: Pancras
 * @Date: 2022-06-27 12:00:56
 * @LastEditors: Adlj
 * @LastEditTime: 2022-10-12 19:23:07
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store/index'
// 工具类
import { getToken, setToken, removeToken } from '@/libs/auth'
import util from '@/libs/util'

import { MessageBox } from 'element-ui'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/**
 * 路由拦截
 * 权限验证
 */
router.beforeEach((to, from, next) => {
  // 获取 Token
  const token = getToken()

  // 判断是否是用户中心页面
  if (to.fullPath.startsWith('/uc')) {
    // Token 为空，说明未登录
    if (token && token !== 'undefined') {
      next()
    } else {
      // 如果访问页面是登陆页面,则直接放行
      if (to.path === '/login') {
        next()
      } else {
        // 跳转至登录页面
        next({ name: 'login' })
      }
    }
  } else {
    next()
  }
})

router.afterEach(to => {
  // 更改标题
  util.title(to.meta.title)
  window.scrollTo(0, 0)
})

export default router
